.noP {
   padding-right: 0px !important;
   padding-left: 0px !important;
}

.customDatepicker {
   border: none !important;
}

.customDatepicker:focus {
   box-shadow: none !important;
}

.mainBoxCopy {
   .label {
      font-size: 20px;
      color: #577df7;
      font-weight: 800;
   }

   .drop {
      width: 100%;
   }

   .label {
      font-weight: 700;
      font-size: 16px;
   }

   .highlight {
      color: #232323 !important;
   }

   .libselect {
      margin-top: 10px;
   }

   .selectLibrary {
      width: 100%;
   }

   .dropdown-menu {
      width: 100% !important;
   }
}

.mainBox {
   &.col-xl-6 {
      background-color: #fff;
      border-radius: 8px;
      margin: auto;
      margin-top: 20px;
      padding: 20px;
      min-height: 100px;
      height: auto;
      transition: .3s;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

      .lectureLabel {
         text-align: left;
         color: #577df7;
         padding-bottom: 5px;
         border-bottom: #577df7 2px solid;
      }

      .createButton {
         transition: .5s;
         width: 100%;
         margin-top: 20px;
         border-radius: 8px;
         height: 40px;
         line-height: 40px;
         color: #fff;
         background-color: #577df7 !important;
         border: none !important;
      }

      .createButton:hover {
         cursor: pointer;
         background-color: #577DF7 !important;
         border: none !important;
      }

      .newAssignmentRow {
         padding-top: 5px;
         margin: 0px !important;

         .dropdownActions {
            border: none !important;
            border-radius: 0px !important;
            border-bottom: 1px solid #577df7 !important;
            box-shadow: none !important;
         }

         .inputLabel {
            height: 37px;
            line-height: 37px;
            text-align: right;
         }

         .inputBox {
            height: auto !important;
            border-bottom: 1px solid #577df7;
            text-align: left;
            height: 37px;

            &.checkbox {
               width: 30px;
               border: none !important;
               padding: 0px !important;

               input {
                  margin-top: 9px;
                  width: 16px;
                  height: 16px;
               }
            }

            &.textArea {
               height: auto !important;
               border: none !important;
               padding: 0px !important;

               .textAreaClass {
                  border: 1px solid #577df7 !important;
               }
            }

            input {
               width: 100%;
               height: 36px;
            }
         }
      }
   }
}