.labelBar {
   .label {
      display: flex;

      .labelMain {
         font-size: 25px;
         font-weight: 700;
         color: #1e1e20 !important;
         text-align: left !important;
         line-height: 50px;
      }

      .newButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: auto;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .newButtonMain:hover {
         border-color: #577df7 !important;
      }

      .infoButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: 20px;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .infoButtonMain:hover {
         border-color: #577df7 !important;
      }
   }
}

.pageRow {
   margin-top: 20px !important;

   .searchBox {
      .settingBox {
         background-color: #fff;
         border-radius: 8px;
         margin: auto;
         margin-top: 10px;
         padding: 20px;
         min-height: 300px;
         height: auto;
         transition: .3s;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

         .topBarDiv {
            margin: 0px !important;
            border-bottom: 2px solid #dddddd;
            margin-bottom: 10px !important;

            .friendRequestLabel {
               text-align: left;
               padding: 17px;
               height: 60px;
               font-size: 18px;
               font-weight: 700;
            }

            .filesLabel {
               font-size: 20px;
               font-weight: 700;
               color: #577df7;
               text-align: left;
               line-height: 58px;
            }

            .searchBarDiv {
               padding: 10px;

               .searchBar {
                  transition: .5s;
                  border: 2px solid #dddddd !important;
                  border-radius: 4px;
                  margin-right: 10px;
               }

               .searchBar:focus {
                  border: 2px solid #577df7 !important;
                  box-shadow: none !important;
               }

               .searchButton {
                  transition: .5s;
                  background-color: #577df7 !important;
                  border: none !important;
               }

               .searchButton:hover {
                  background-color: #577DF7 !important;
                  border: none !important;
               }
            }
         }

         .noUserFound {
            width: 100%;
            display: block;
            padding: 30px 10px;
         }

         .customTableRowLabel {
            border: none !important;
            border-width: 0px !important;
            border-bottom: 1px solid #c9c8ff !important;
            background-color: #fff !important;
            transition: .5s;

            th {
               width: 20% !important;
               color: #577df7 !important;
               border-width: 0px !important;
               border: none !important;
               cursor: default !important;
            }
         }

         .customTableRow {
            border-top: none !important;
            display: inline-table;
            width: 100%;
            border-bottom: 1px solid #c9c8ff !important;
            background-color: #fff !important;
            transition: .5s;

            td {
               word-break: break-word;
               width: 20% !important;
               line-height: 53px;
               border: none !important;
               border-width: 0px !important;
               --bs-table-accent-bg: none !important;
               transition: .5s;
               color: #1e1e20 !important;
               cursor: pointer;
            }

            .userImageClass {
               padding: 0px !important;
               line-height: 50px !important;
            }

            .acceptButton {
               margin-left: 5px;
               background-color: #577df7 !important;
            }

            .acceptButton:hover {
               background-color: #706ee2 !important;
            }
         }

         .customTableRow:hover {
            background-color: #eef2fe !important;
         }

         .dropdownActions {
            background-color: #577df7 !important;
            border: none !important;
            box-shadow: none !important;
         }
      }

      .settingBox:hover {
         cursor: default !important;
         box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
   }
}