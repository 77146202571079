.navbar {
   transition: .5s;
   background-color: #fff;
   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
   padding: 0px !important;

   .navbarImg {
      img {
         border: 1px solid #577df7;
         border-radius: 100%;
         object-fit: cover;
      }
   }

   .mobileProfile {
      display: flex;

      .userText {
         text-align: left;

         .userName {
            padding: 12px 10px 0px 10px;
            color: #577DF7;
            font-weight: 600;
            font-size: 16px;
            display: block;
         }

         .roleName {
            display: block;
            padding: 0px 10px 5px 10px;
            font-weight: 600;
            font-size: 16px;
            color: #B2B0FF;
         }
      }
   }

   .notificationDrop {
      height: 70px;

      .tBodyNoti {
         position: relative;
         min-height: 200px;
         display: table-caption;
         max-height: 400px;
         overflow: auto;

         &::-webkit-scrollbar-track {
            right: 0px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
            border-radius: 12px;
         }

         &::-webkit-scrollbar {
            width: 12px;
            background-color: #F5F5F5;
         }

         &::-webkit-scrollbar-thumb {
            border-radius: 12px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: #577df7;
         }
      }

      .notificationInfo {
         position: absolute;
         line-height: 22px;
         border-radius: 100%;
         font-size: 10px;
         width: 22px;
         height: 22px;
         color: #fff;
         left: 5px;
         background-color: #ff4444;
         bottom: 10px;
      }

      .notifButton {
         height: 70px;
         background-color: #fff;
         border: none !important;

         &:focus {
            box-shadow: none !important;
         }
      }

      .friendRequestLabel {
         text-align: left;
         padding: 17px;
         height: 60px;
         font-size: 18px;
         font-weight: 700;
      }

      .noUserFound {
         width: 100%;
         display: block;
         text-align: center;
         padding: 30px 10px;
      }

      .customTableRowLabel {
         border: none !important;
         border-width: 0px !important;
         background-color: #fff !important;
         transition: .5s;

         th {
            width: 20% !important;
            color: #577df7 !important;
            border-width: 0px !important;
            border: none !important;
            cursor: default !important;
         }
      }

      .customTableRow {
         border-top: none !important;
         display: inline-block;
         width: 100%;
         border-radius: 8px;
         box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
         background-color: #fff !important;
         transition: .5s;
         margin-bottom: 10px;

         .icon {
            width: 1% !important;
            padding: 0px 0px 0px 10px !important;
         }

         td {
            word-break: break-word;
            width: 20% !important;
            line-height: 53px;
            border: none !important;
            border-width: 0px !important;
            --bs-table-accent-bg: none !important;
            transition: .5s;
            color: #1e1e20 !important;
            cursor: pointer;
            padding-left: 5px !important;
            vertical-align: middle;

            span {
               display: block;
               line-height: 20px !important;
               margin-left: 5px;
            }
         }

         .userImageClass {
            padding: 0px !important;
            line-height: 50px !important;
         }

         .acceptButton {
            margin-left: 5px;
            background-color: #577df7 !important;
         }

         .acceptButton:hover {
            background-color: #706ee2 !important;
         }
      }

      .customTableRow:hover {
         background-color: #eef2fe !important;
      }

      .dropdownActions {
         background-color: #577df7 !important;
         border: none !important;
         box-shadow: none !important;
      }

      .notiButton {
         height: 100%;
      }

      .dropdown-toggle::after {
         display: none !important;
      }

      .dropdown-menu {
         width: 450px;
         transform: translate(-50%, 0px);
      }
   }

   .customNavbarDropdown {
      display: flex;
      width: 350px;
      padding-left: 0px !important;
      height: 70px;

      .userText {
         width: 200px;
         text-align: left;

         .userName {
            padding: 12px 10px 0px 10px;
            width: 200px;
            color: #577DF7;
            font-weight: 600;
            font-size: 16px;
            display: block;
         }

         .roleName {
            display: block;
            padding: 0px 10px 5px 10px;
            width: 200px;
            font-weight: 600;
            font-size: 16px;
            color: #B2B0FF;
         }
      }
   }

   .mobileNavbarTop {
      height: 70px;
   }

   .mobileNavbarBottom {
      height: 70px;
      display: flex;

      .col-sm-4 {
         flex: auto !important;
      }

      .mobileTopLeft {
         width: 20%;
      }

      .mobileTopRight {
         width: 80%;
         display: flex;

         .userText {
            text-align: left;

            .userName {
               width: 200px;
               text-align: right;
               padding: 12px 10px 0px 10px;
               color: #577DF7;
               font-weight: 600;
               font-size: 16px;
               display: block;
            }

            .roleName {
               display: block;
               text-align: right;
               padding: 0px 10px 5px 10px;
               font-weight: 600;
               font-size: 16px;
               color: #B2B0FF;
            }
         }

         .userImg {
            width: 50px;

            img {
               object-fit: cover;
               border-radius: 100%;
               border: 1px solid #577df7;
            }
         }
      }
   }

   .brandCenter {
      margin: 0px !important;
      width: 357px;

      img {
         float: left;
      }
   }

   .me-auto {
      &.navbar-nav {
         margin: auto !important;
         height: 70px !important;

         a {
            color: #577DF7;
            font-weight: 700;
         }

         .nav-link {
            height: 70px;
            transition: .5s;
            border-bottom: 2px solid #fff;

            &.customIcon {
               line-height: 50px;
               position: relative;

               .notificationInfo {
                  position: absolute;
                  line-height: 22px;
                  border-radius: 100%;
                  font-size: 10px;
                  width: 22px;
                  height: 22px;
                  color: #fff;
                  left: 5px;
                  background-color: #ff4444;
                  bottom: 10px;
               }
            }
         }

         .nav-link:hover {
            border-bottom: 2px solid #577df7;
         }
      }

      &.right {
         margin-right: 0px !important;
         margin-left: 0px !important;

         a {
            margin: auto;
            margin-right: 20px;
            height: fit-content;
            display: block;
            width: 100%;
         }

         .loginNavButton {
            margin: auto;
            background-color: #706ee2;
            padding: 10px 30px;
            font-size: 15px;
            border: 1px solid #706ee2;
            color: #fff;
            transition: .5s;
            border-radius: 8px;

            &:hover {
               border-color: #706ee2;
               background-color: #fff;
               color: #706ee2;
            }
         }

         .signupNavButton {
            background-color: #fff;
            padding: 10px 30px;
            font-size: 15px;
            border: 1px solid #fff;
            color: #706ee2;
            transition: .5s;
            border-radius: 8px;

            &:hover {
               background-color: #dfdfdf;
               color: #706ee2;
            }
         }

         .nav-item {
            margin-left: auto;

            .collasible-nav-dropdown {
               right: 0px !important;
            }
         }

         .customNavbarDropdown {
            display: flex;
            width: 300px;
            padding-left: 50px;
            height: 70px;

            .userText {
               width: 200px;
               text-align: left;

               .userName {
                  padding: 12px 10px 0px 10px;
                  width: 200px;
                  color: #577DF7;
                  font-weight: 600;
                  font-size: 16px;
                  display: block;
               }

               .roleName {
                  display: block;
                  padding: 0px 10px 5px 10px;
                  width: 200px;
                  font-weight: 600;
                  font-size: 16px;
                  color: #B2B0FF;
               }
            }

            .dropdownMenuDiv {
               position: absolute;
               right: 0px;
               top: 45px;
            }

            .dropdownNavbar {
               margin-top: 15px;
               width: 40px;
               height: 40px;
               border-radius: 100px;
               background-color: #577DF7;
               border: none;
            }

            .dropdownNavbar:hover {
               background-color: #9391fa;
            }

            .dropdownNavbar:focus {
               box-shadow: none;
            }
         }
      }
   }
}

.customNavbarMobileTop {
   border-radius: 0px 0px 10px 10px;
}