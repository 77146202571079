.messageLabelBar {}

.messageMain {
   border-radius: 8px;
   margin: auto;
   padding: 20px;
   transition: .3s;
   box-shadow: r;

   .label {
      padding-left: 5px;
      display: flex;

      .labelMain {
         font-size: 25px;
         font-weight: 700;
         color: #1e1e20 !important;
         text-align: left !important;
         line-height: 50px;
      }

      .newButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: 15px;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .newButtonMain:hover {
         border-color: #577df7 !important;
      }
   }

   .leftBox {
      .messageLabel {
         width: 100%;
         border-radius: 8px 8px 0px 0px;
         color: #fff;
         font-size: 20px;
         text-align: left;
         font-weight: 700;
         padding: 10px 0px;
         background-color: #577df7;
      }

      .messageList {
         height: auto;
         margin-bottom: 20px;
         border-radius: 0px 0px 8px 8px;

         .messageListLabel {
            color: #577df7 !important;
            font-size: 18px;
            margin-left: 5px;
            text-align: left;
            font-weight: 700;
            padding: 5px 0px;
         }

         .messageUserButton {
            text-align: left;
            color: #577df7;
            padding: 5px 0px;
            padding-left: 5px;
            transition: .5s;
            border-radius: 8px;
         }

         .messageUserButton:hover {
            cursor: pointer;
            background-color: #577df7;
            color: #fff;
            ;
         }
      }
   }

   .rightBox {
      border-radius: 8px;

      .userName {
         font-size: 18px;
         text-align: left;
         padding: 5px 0px;
         color: #577df7;
         font-weight: 700;
         border-bottom: #cecece 1px solid;
      }

      .messageBox {
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         background-color: #fff;
         height: calc(100vh - 340px);
         min-height: 300px;
         padding: 10px;

         .messageArea {
            height: calc(100vh - 400px);
            overflow: auto;
            min-height: 240px;

            &::-webkit-scrollbar-track {
               border-radius: 8px;
               background-color: #F5F5F5;
            }

            &::-webkit-scrollbar {
               width: 12px;
               background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
               border-radius: 8px;
               background-color: #577df7;
            }

            .userMessage {
               width: 100%;
               display: inline-block;
               margin-bottom: 10px;

               .userMessageInside {
                  margin-right: 5px;
                  padding: 5px 10px;
                  border-radius: 8px;
                  margin-left: auto;
                  min-width: 30px;
                  max-width: fit-content;
                  background-color: #7a98f9;
               }

               .messageTime {
                  text-align: right;
                  font-size: 13px;
                  margin-top: auto;
               }

               span {
                  color: #fff;
                  margin-right: 5px;
                  min-width: 50px;
                  max-width: 100%;
                  color: #fff;
                  text-align: left;
                  display: block;
                  word-break: break-word;
               }
            }

            .otherMessage {
               max-width: 85%;
               margin-bottom: 10px;

               .OtherMessageInside {
                  background-color: #577DF7;
                  min-width: 30px;
                  max-width: fit-content;
                  padding: 7px 10px 10px 10px;
                  border-radius: 8px;
               }

               span {
                  min-width: 50px;
                  max-width: 100%;
                  color: #fff;
                  text-align: left;
                  display: block;
                  word-break: break-word;
               }

               .messageTime {
                  text-align: right;
                  font-size: 13px;
                  margin-top: auto;
               }

               .userName {
                  padding: 0px 5px 3px 5px;
                  min-width: 50px;
                  background-color: transparent;
                  border: none !important;
                  color: #fff;
                  font-weight: 800;
                  font-size: 14px;
                  border-radius: 8px;
                  max-width: fit-content;
               }
            }
         }

         .messageWriteArea {
            padding-top: 5px;
            display: flex;
            border-top: #577df7 1px solid;

            .customDrop {
               padding: 0px !important;
               height: 36px;
               background-color: transparent;
               border: none;

               &:focus {
                  box-shadow: none !important;
               }
            }

            .dropdown-menu {
               background-color: transparent;
               border: none;
               transform: translate(0px, 0px) !important;
            }

            .dropdown-toggle::after {
               display: none;
            }

            .emoji-picker-react {
               position: absolute !important;
               bottom: 10px !important;
               right: 85px !important;

               input.emoji-search {
                  margin: 0px !important;
               }
            }

            input {
               width: 100%;
            }
         }
      }
   }
}

.messageForMobile {
   padding: 0px;
   background-color: #fff;
   height: calc(100vh - 140px);

   .messageMain {
      border-radius: 8px;
      margin: auto;
      padding: 0px;
      margin: 0px;
      transition: .3s;
      box-shadow: r;

      .label {
         padding-left: 5px;
         display: flex;

         .labelMain {
            font-size: 25px;
            font-weight: 700;
            color: #1e1e20 !important;
            text-align: left !important;
            line-height: 50px;
         }

         .newButtonMain {
            padding: 10px 20px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
            margin-left: 15px;
            border: 2px solid #fff;
            transition: .5s;

            .buttonText {
               margin-left: 10px;
               font-size: 16px;
               font-weight: 700;
               color: #577df7;
            }
         }

         .newButtonMain:hover {
            border-color: #577df7 !important;
         }
      }

      .leftBox {
         border-radius: 8px;
         width: 100%;

         .messageLabel {
            width: 100%;
            border-radius: 8px 8px 0px 0px;
            color: #fff;
            font-size: 20px;
            text-align: left;
            font-weight: 700;
            padding: 10px 0px;
            background-color: #577df7;
         }

         .messageList {
            height: auto;
            margin-bottom: 20px;
            border-radius: 0px 0px 8px 8px;

            span {
               margin-left: 5px;
            }

            .messageListLabel {
               background-color: #577df7 !important;
               font-size: 18px;
               margin-left: 0px;
               color: #fff !important;
               text-align: left;
               font-weight: 700;
               padding: 10px 10px;
            }

            .messageUserButton {
               border: none;
               border-bottom: 1px solid rgba(60, 64, 67, 0.1);
               text-align: left;
               color: #577df7;
               padding: 10px 10px;
               border-radius: 0px;
               transition: .5s;
            }

            .messageUserButton:hover {
               cursor: pointer;
               background-color: #577df7;
               color: #fff;
               ;
            }
         }
      }

      .rightBox {
         border-radius: 8px;
         transition: .5s;
         top: 100%;
         height: 100vh;
         width: 100%;
         background-color: #fff;
         left: 0;
         position: absolute;

         &.active {
            top: 50px;
         }

         .topBarMessage {
            display: flex;
            border-bottom: #cecece 1px solid;

            .backButton {
               width: 100px;
               padding: 15px 10px;
               font-weight: 800;
            }

            .userName {
               padding: 15px 10px;
               font-size: 18px;
               height: 50px;
               text-align: left;
               color: #577df7;
               font-weight: 700;
               border: none !important;
            }
         }

         .messageBox {
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
            background-color: #fff;
            height: calc(100vh - 200px);
            min-height: 300px;
            padding: 10px;

            .messageArea {
               height: calc(100vh - 260px);
               overflow: auto;
               min-height: 240px;

               &::-webkit-scrollbar-track {
                  border-radius: 8px;
                  background-color: #F5F5F5;
               }

               &::-webkit-scrollbar {
                  width: 12px;
                  background-color: #F5F5F5;
               }

               &::-webkit-scrollbar-thumb {
                  border-radius: 8px;
                  background-color: #7a98f9;
               }

               .userMessage {
                  width: 100%;
                  display: inline-block;
                  margin-bottom: 10px;

                  .userMessageInside {
                     margin-right: 5px;
                     padding: 5px 10px;
                     border-radius: 8px;
                     margin-left: auto;
                     min-width: 30px;
                     max-width: fit-content;
                     background-color: #7a98f9;
                  }

                  .messageTime {
                     text-align: right;
                     font-size: 13px;
                     margin-top: auto;
                  }

                  span {
                     color: #fff;
                     margin-right: 5px;
                     min-width: 50px;
                     max-width: 100%;
                     color: #fff;
                     text-align: left;
                     display: block;
                     word-break: break-word;
                  }
               }

               .otherMessage {
                  max-width: 85%;
                  margin-bottom: 10px;

                  .OtherMessageInside {
                     background-color: #577DF7;
                     min-width: 30px;
                     max-width: fit-content;
                     padding: 7px 10px 10px 10px;
                     border-radius: 8px;
                  }

                  span {
                     min-width: 50px;
                     max-width: 100%;
                     color: #fff;
                     text-align: left;
                     display: block;
                     word-break: break-word;
                  }

                  .messageTime {
                     text-align: right;
                     font-size: 13px;
                     margin-top: auto;
                  }

                  .userName {
                     padding: 0px 5px 3px 5px;
                     min-width: 50px;
                     background-color: transparent;
                     border: none !important;
                     color: #fff;
                     font-weight: 800;
                     font-size: 14px;
                     border-radius: 8px;
                     max-width: fit-content;
                  }
               }
            }

            .messageWriteArea {
               padding-top: 5px;
               display: flex;
               border-top: #577df7 1px solid;

               .customDrop {
                  padding: 0px !important;
                  height: 36px;
                  background-color: transparent;
                  border: none;

                  &:focus {
                     box-shadow: none !important;
                  }
               }

               .dropdown-menu {
                  background-color: transparent;
                  border: none;
                  inset: unset !important;
                  right: 0px !important;
                  transform: translate(0px, 0px) !important;
               }

               .dropdown-toggle::after {
                  display: none;
               }

               .emoji-picker-react {
                  position: absolute !important;
                  bottom: 10px !important;
                  right: 85px !important;

                  input.emoji-search {
                     margin: 0px !important;
                  }
               }

               input {
                  width: 100%;
               }
            }
         }
      }
   }
}