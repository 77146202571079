.animCenter {
   position: absolute;
   top: 50%;
   /* position the top  edge of the element at the middle of the parent */
   left: 50%;
   /* position the left edge of the element at the middle of the parent */
   transform: translate(-50%, -50%);

   .spinner-border {
      border: 0.25em solid #577df7;
      border-right-color: transparent;
      width: 5rem;
      height: 5rem;
   }
}

.noClass {
   font-size: 25px;
   color: #577df7;
   height: 300px;
   line-height: 300px;
}

.modal-dialog {
   min-width: 600px;
}

.labelBar {
   .label {
      display: flex;

      .labelMain {
         font-size: 25px;
         font-weight: 700;
         color: #1e1e20 !important;
         text-align: left !important;
         line-height: 50px;
      }

      .newButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: 10px !important;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .newButtonMain:hover {
         cursor: pointer;
         border-color: #577df7 !important;
      }
   }
}

.pageRow {
   margin-top: 20px !important;

   .col-xl-4 {
      .settingBox {
         background-color: #fff;
         border-radius: 8px;
         margin: auto;
         padding: 20px;
         height: 370px;
         overflow: auto;
         margin-bottom: 20px;
         transition: .3s;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

         &::-webkit-scrollbar-track {
            right: 0px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;

         }

         &::-webkit-scrollbar {
            width: 6px;
            background-color: #F5F5F5;
         }

         &::-webkit-scrollbar-thumb {
            border-radius: 12px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: #577df7;
         }

         .imgClass {
            width: 100%;
            height: 230px;
         }

         .topBox {
            width: 100%;
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #B6B6B6;
            height: 50px;

            &.second {
               margin-top: 20px;
            }

            .topLabel {
               line-height: 40px;
               flex-grow: 2;
               height: 40px;
               text-align: left;
               font-weight: 700;
               padding: 0px !important;
               border: none !important;
            }

            .dropdown-menu {
               transform: translate(-120px, 36px) !important;
            }

            .buttonText {
               margin-left: 10px;
               font-size: 16px;
               font-weight: 700;
               color: #577df7;
            }

            .customDrop {
               padding: 0px !important;
               height: 36px;
               background-color: transparent;
               border: none;

               &:focus {
                  box-shadow: none !important;
               }
            }

            .dropdown-toggle::after {
               display: none;
            }
         }

         .topLabel {
            flex-grow: 2;
            height: 20px;
            text-align: left;
            font-weight: 700;
            padding-bottom: 35px;
            border-bottom: 1px solid #B6B6B6;
         }

         .last_nextClasss {
            text-align: left;
            font-size: 14px;
         }

         .tableRow {
            transition: .5s;
            margin-top: 10px;
            display: flex;

            &.noHover {
               &.color {
                  background-color: #577df7;
                  border-radius: 4px;
                  padding: 5px;
               }

               &:hover {
                  background-color: #577df7;
                  cursor: default !important;
               }
            }

            .text {
               margin-left: 15px;
               line-height: 30px;
               font-size: 14px;

               &.colored {
                  font-weight: 500;
                  font-size: 16px !important;
                  margin-left: 10px;
                  color: #fff;
               }
            }
         }

         .tableRow:hover {
            cursor: pointer;
            background-color: #f2f2f2;
            border-radius: 4px;
         }

         .textClass {
            width: 100%;
            font-size: 18px;
            font-weight: 700;
            color: #577df7;
            line-height: 50px;
            height: 50px;
         }

         .imgClass {
            padding-top: 50px;
         }
      }

      .settingBox:hover {
         box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
   }
}

.labelBar {
   .label {
      display: flex;

      .labelMain {
         font-size: 25px;
         font-weight: 700;
         color: #1e1e20 !important;
         text-align: left !important;
         line-height: 50px;
      }

      .newButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: auto;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .newButtonMain:hover {
         border-color: #577df7 !important;
      }

      .infoButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: 20px;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .infoButtonMain:hover {
         border-color: #577df7 !important;
      }
   }
}

.pageRow {
   margin-top: 20px !important;

   .col-xl-12 {

      .settingBox {
         background-color: #fff;
         border-radius: 8px;
         margin: auto;
         padding: 20px;
         height: auto;
         transition: .3s;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

         .topBarDiv {
            margin: 0px !important;
            border-bottom: 2px solid #dddddd;
            margin-bottom: 10px !important;

            .filesLabel {
               font-size: 20px;
               font-weight: 700;
               color: #577df7;
               text-align: left;
               line-height: 58px;
            }

            .searchBarDiv {
               padding: 10px;

               .dropdownSearchOutside {
                  width: 100%;

                  .dropdownSearch {
                     background-color: #577df7;
                     outline: none !important;
                     border: none !important;
                     width: 100%;
                  }

                  .dropdown-menu {
                     width: 100%;
                     overflow-y: auto;
                     overflow-x: hidden;
                     height: 400px;

                     &::-webkit-scrollbar-track {
                        background-color: #F5F5F5;
                     }

                     &::-webkit-scrollbar {
                        width: 12px;
                        background-color: #F5F5F5;
                     }

                     &::-webkit-scrollbar-thumb {
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                        background-color: #577df7;
                     }
                  }
               }

               .searchBar {
                  transition: .5s;
                  border: 2px solid #dddddd !important;
                  border-radius: 4px;
                  width: calc(100% - 20px);
                  margin: 10px;
               }

               .searchBar:focus {
                  border: 2px solid #577df7 !important;
                  box-shadow: none !important;
               }

               .searchButton {
                  margin-top: 10px;
                  height: 40px;
                  transition: .5s;
                  background-color: #577df7 !important;
                  border: none !important;
               }

               .searchButton:hover {
                  background-color: #577DF7 !important;
                  border: none !important;
               }
            }
         }

         .customTableRowLabel {
            border: none !important;
            border-width: 0px !important;
            border-bottom: 1px solid #c9c8ff !important;
            background-color: #fff !important;
            transition: .5s;

            th {
               width: 20% !important;
               color: #577df7 !important;
               border-width: 0px !important;
               border: none !important;
               cursor: default !important;
            }
         }

         .customTableRow {
            border-top: none !important;
            border-bottom: 1px solid #c9c8ff !important;
            background-color: #fff !important;
            transition: .5s;

            td {
               word-break: break-word;
               max-width: 20% !important;
               border: none !important;
               border-width: 0px !important;
               --bs-table-accent-bg: none !important;
               transition: .5s;
               color: #1e1e20 !important;
               cursor: pointer;

               .pendingUser {
                  border: none !important;
                  background-color: #577df7 !important;
               }
            }

            .userImageClass {
               padding: 0px !important;
               line-height: 50px !important;

               img {
                  border-radius: 100%;
                  margin-left: 10px;
                  width: 35px;
                  height: 35px;
               }
            }
         }

         .customTableRow:hover {
            background-color: #eef2fe !important;
         }

         .dropdownActions {
            background-color: #577df7 !important;
            border: none !important;
            box-shadow: none !important;
         }
      }

      .settingBox:hover {
         cursor: default !important;
         box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
   }
}