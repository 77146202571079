@media screen and (max-width:768px) {
   .mainBoxLandingTop {
      padding: 20px !important;
      margin-top: 50px;
      height: 650px !important;

      .rowOutside {
         padding: 0px !important;

         .mainInside {
            &.text {
               padding: 0px !important;
               margin-top: 30px !important;
               margin: 0px !important;

               .text {
                  color: #474747;
                  font-size: 16px !important;
                  font-weight: 500;
                  margin-top: 20px !important;
               }

               .subText {
                  font-size: 25px !important;
               }

               .textLabel {
                  font-size: 35px !important;
               }

               .goProfileButton {
                  font-size: 18px !important;
               }
            }
         }
      }
   }

   .mainBoxLandingMid {
      padding: 10px !important;

      .mainOutside {
         margin-top: 20px;
      }
   }
}

.mainBoxLanding {
   padding: 20px;
   min-height: 400px;
   background-color: #fff;

   .rowOutside {
      border-radius: 8px;
      margin: auto;
      padding: 10px;
      min-height: 200px;
      height: auto;
      transition: .5s;

      .mainInside {
         height: auto;

         &.text {
            border-radius: 8px;
            background-color: #577df7;
            padding: 40px;

            .text {
               color: #fff;
               font-size: 16px;
               font-weight: 500;
            }

            .textLabel {
               text-align: left;
               font-size: 20px;
               color: #fff;
               font-weight: 800;
            }
         }

         .textLabel {
            text-align: left;
            font-size: 20px;
            color: #232323;
            font-weight: 800;
         }

         .text {
            color: #565656;
            font-size: 16px;
            font-weight: 500;
         }
      }
   }
}

.containerFull {
   padding: 0px !important;

   .header {
      font-size: 20px;
      font-weight: 800;
      text-align: center;
      background-color: #f5f5f5;
      color: #232323;
      padding: 30px
   }

   .description {
      min-height: calc(100vh - 224px);
      background-color: #fff;
      padding: 30px;
      text-align: left;
   }
}

.mainBoxLandingMid {
   padding-top: 100px;
   padding-bottom: 100px;
   background-color: #577df7;

   .rowOutside {
      justify-content: center;
      gap: 5%;
   }

   .mainOutside {
      padding: 20px;
      transition: .5s;
      border-radius: 8px;
      background-color: #fff;

      &:hover {
         box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      }
   }

   .mainInside {
      width: fit-content;
      margin: auto;
      padding: 30px;
      border-radius: 100%;
      border: 3px solid #577df7;
   }

   .textLabel {
      text-align: center;
      font-size: 25px;
      color: #232323;
      font-weight: 800;
      padding: 20px;

      // border-bottom: 2px solid #e0e0e0;
      .text {
         color: #474747;
         font-size: 16px;
         font-weight: 500;
         margin-top: 10px;
      }
   }
}

.mainBoxLandingTop {
   padding: 20px;
   height: 500px;
   background-color: #fff;

   .rowOutside {
      background-color: #fff;
      border-radius: 8px;
      margin: auto;
      padding: 10px;
      min-height: 200px;
      height: 100%;
      transition: .5s;

      .mainInside {
         margin: auto;

         &.text {
            border-radius: 8px;
            background-color: #fff;
            padding: 40px;

            .text {
               color: #474747;
               font-size: 20px;
               font-weight: 500;
               margin-top: 20px;
            }

            .subText {
               border-radius: 8px;
               background-color: #577df7;
               padding: 5px 5px 5px 15px;
               color: #fff;
               font-size: 35px;
               margin-top: 0px;
            }

            .textLabel {
               text-align: left;
               font-size: 50px;
               color: #577df7;
               font-weight: 800;
            }

            .goProfileButton {
               font-size: 18px;
               margin-top: 20px;
               font-weight: 500;
               border-radius: 8px;
               width: fit-content;
               color: #fff;
               padding: 10px;
               background-color: #363636;
               transition: .5s;

               &:hover {
                  cursor: pointer;
                  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                  background-color: #4d4d4d;
               }
            }

            .goLoginButton {
               font-size: 18px;
               float: left;
               margin-top: 20px;
               font-weight: 500;
               border-radius: 8px;
               width: fit-content;
               color: #fff;
               padding: 10px 20px;
               background-color: #577df7;
               transition: .5s;
               border: 1px solid #577df7;

               svg {
                  margin-right: 5px;
               }

               &:hover {
                  cursor: pointer;
                  color: #577df7;
                  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                  background-color: #fff;

                  svg {
                     fill: #577df7
                  }
               }
            }

            .goSignUpButton {
               font-size: 18px;
               float: left;
               margin-left: 10px;
               margin-top: 20px;
               font-weight: 500;
               border-radius: 8px;
               width: fit-content;
               color: #577df7;
               padding: 10px 20px;
               background-color: #fff;
               transition: .5s;
               border: 1px solid #577df7;

               svg {
                  margin-right: 5px;
               }

               &:hover {
                  cursor: pointer;
                  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                  background-color: #577df7;
                  color: #fff;

                  svg {
                     fill: #fff
                  }
               }
            }
         }

         .textLabel {
            text-align: left;
            font-size: 20px;
            color: #232323;
            font-weight: 800;
         }

         .text {
            color: #565656;
            font-size: 16px;
            font-weight: 500;
         }
      }
   }
}

.bottomCopyright {
   width: 100%;
   background-color: #577df7;
   padding: 20px;
   text-align: center;
   color: #fff;
}

.footer {
   padding: 50px 100px;
   background-color: #577df7;

   .footerInside {
      text-align: left;
      padding: 10px;

      .header {
         font-size: 22px;
         color: #fff;
         padding: 0px;
         background-color: transparent;
         text-align: left;
         border-radius: 8px;
      }

      .links {
         margin-top: 20px;
         font-size: 17px;
         color: #e0e0e0;

         a {
            font-size: 17px;
            color: #e0e0e0;
         }

         span {
            padding-bottom: 5px;
            cursor: pointer;
            display: block;

            &:hover {
               text-decoration: underline;
            }
         }
      }
   }
}