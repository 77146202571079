body {
  background-color: #eef2fe;
  height: 100vh;
  overflow: hidden;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #577df7;
  }
}

.App {
  text-align: center;
  background-color: #eef2fe;

  a {
    text-decoration: none !important;
  }

  .main {
    padding-top: 0px !important;
  }

  .container {
    min-height: 400px;
    margin-bottom: 60px;
  }

  .bottomCopyrightRouter {
    width: 100%;
    background-color: #577df7;
    padding: 5px;
    position: absolute;
    font-size: 13px;
    bottom: 0px;
    text-align: center;
    color: #fff;
  }

  .customDiv {
    scroll-behavior: smooth;
    overflow: auto;
    height: calc(100vh - 70px);
    // padding-bottom: 15px;
    padding-top: 30px;

    &.login {
      height: 100vh !important;
    }

    &::-webkit-scrollbar-track {
      right: 0px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #577df7;
    }
  }

  @media screen and (max-width:1200px) {
    .bottomCopyrightRouter {
      display: none !important;
    }

    .customDiv {
      height: 100vh !important;
      padding-top: 70px !important;
      overflow-x: hidden !important;
    }

    .main {
      padding-top: 0px !important;
    }

    .row {
      --bs-gutter-x: 0px;
    }

    .selectAudio {
      margin-top: 10px;
      padding: 0px !important;
    }

    .modal {
      padding-left: 0px !important;
    }

    .inputBox {
      margin-bottom: 5px;
    }

    .inputLabel {
      text-align: left !important;
    }

    .rightBox {
      margin-top: 20px !important;
    }

    .form {
      .label {
        text-align: left !important;
        border-bottom: #577df7 1px solid;
      }
    }

    .rightTopBar {
      flex-wrap: wrap;
      height: auto !important;

      .userName {
        width: 100%;
      }

      .selectLang {
        width: 100%;

        span {
          width: 100%;
          display: block;
          text-align: left !important;
          padding-right: 10px;
          height: 40px;
          line-height: 40px;
        }

        .uploadImgButton {
          background-color: #fff !important;

          &.lang {
            width: 100% !important;
            float: right;
            margin-top: 0px !important;
          }
        }
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  top: 0;
  position: absolute;
  opacity: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.Scene {
  background-color: rgba(0, 0, 0, .3);
  height: 400px !important;
}