.loginBoxMain {
  background-color: #fff;
  border-radius: 8px;
  margin: auto;
  padding: 20px;
  height: auto;
  transition: .3s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  .loginText {
    font-size: 20px;
    color: #577df7;
    font-weight: 700 !important;
  }

  .loginInputs {
    padding: 10px;

    input {
      width: 100%;
      padding: 5px;
      border-radius: 4px;
      background-color: #fff !important;
      border: 2px solid #d9d9d9;
      transition: .5s;
    }

    input:focus {
      border: 2px solid #577df7 !important;
    }
  }

  .checkboxDiv {
    padding-left: 10px;
    display: flex;

    .form-check-label {
      text-align: left !important;
      height: 24px !important;
      line-height: 24px !important;
      font-size: 12px !important;
    }

    span {
      text-align: left !important;
      height: 24px !important;
      line-height: 24px !important;
      font-size: 12px !important;
    }
  }

  .forgotDiv {
    text-align: right;
  }

  .loginSignupButtonDiv {
    a {
      padding: 0px !important;
    }
  }

  .loginButton {
    background-color: #577df7;
    height: 40px;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    transition: .5s;

    &.Disabled {
      background-color: rgba(60, 64, 67, 0.15);
    }
  }

  .loginButton:hover {
    cursor: pointer;
    background-color: #6e6cd4;
  }

  .signupButton {
    margin-top: 10px;
    background-color: #ffffff;
    height: 40px;
    border-radius: 4px;
    color: #577df7;
    font-size: 18px;
    line-height: 36px;
    transition: .5s;
  }

  .signupButton:hover {
    cursor: pointer;
    background-color: #d9d9d9;
  }
}