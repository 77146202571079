/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Ubuntu-Regular;
  src: url('../fonts/Ubuntu/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url('../fonts/Ubuntu/Ubuntu-Bold.ttf');
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Ubuntu-Regular, sans-serif;
}

/*---------------------------------------------*/
a {
  font-family: Ubuntu-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #403866;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Ubuntu-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li,
option {
  margin: 0px;
  list-style-type: none;
}


/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder {
  color: #8f8fa1;
}

input:-moz-placeholder {
  color: #8f8fa1;
}

input::-moz-placeholder {
  color: #8f8fa1;
}

input:-ms-input-placeholder {
  color: #8f8fa1;
}

textarea::-webkit-input-placeholder {
  color: #8f8fa1;
}

textarea:-moz-placeholder {
  color: #8f8fa1;
}

textarea::-moz-placeholder {
  color: #8f8fa1;
}

textarea:-ms-input-placeholder {
  color: #8f8fa1;
}

label {
  display: block;
  margin: 0;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}


/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: Ubuntu-Regular;
  font-size: 16px;
  color: #577df7;
  line-height: 1.4;
}

.logo {
  background-image: url("../img/MetaCampusLogo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 200px;
  width: 100%;
}

.fullBG {
  background-image: url("Picture1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

.OverButton {
  z-index: 2;
  width: 200px;
  margin-left: calc(50% - 100px);
  float: left;
  margin-top: calc(100vh - 82px);
}

.top-bar-wrapper {
  height: 200px;
  background-color: #FFFFFF;
  width: 100%;
  margin-top: 0px;
}

.user-panel {
  height: 120px;
  width: 400px;
  float: left;
  position: relative;
  margin-left: 25px;
  margin-top: 25px;
}

.top-bar-logo {
  background-image: url("../img/MetaCampusLogo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 193px;
  width: 200px;
  position: absolute;
  left: calc(50% - 129px);
}

.top-bar-menu {
  height: 120px;
  width: 400px;
  float: right;
  position: relative;
  margin-right: 25px;
  margin-top: 25px;
}

.user-panel-picture {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 25px;
  float: left;
  position: relative;
}

.user-panel-picture img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  border-color: black;
  border-width: 2px;
  border: solid;
}

.user-panel-name {
  width: 250px;
  height: 28px;
  font-family: Ubuntu-Regular;
  font-size: 24px;
  line-height: 27.58px;
  color: #577df7;
  margin: 0px;
  float: left;
  position: relative;
  margin-top: 15px;
  margin-bottom: 3px;

  font-weight: bold;
}

.user-panel-detail {
  width: 250px;
  height: 28px;
  font-family: Ubuntu-Regular;
  font-size: 20px;
  line-height: 27.58px;
  color: #B2B0FF;
  margin: 0px;
  float: left;
  position: relative;
}

.menu-button {
  width: 150px;
  height: 28px;
  font-family: Ubuntu-Regular;
  font-size: 20px;
  line-height: 27.58px;
  color: #577df7;
  margin-top: 30px;
  margin-bottom: 40px;
  float: right;
  position: relative;
  font-weight: bold;
}

#main-wrapper {
  height: 350px;
  width: 100%;
  position: absolute;
  /* padding-left: calc(calc(calc(5px - calc(calc(100vh - 200px) / 346)) * 346) + 100px); */
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 60px;
}

.selection-box {
  width: 290px;
  height: 310px;
  float: left;
  position: relative;
  margin: 23px;
  border: 2px solid #577df7;
  box-sizing: border-box;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.selection-box:hover {
  background-color: #ECECFF;
}

.selection-box-icon {
  height: 175px;
  width: 175px;
  margin-left: 56px;
  margin-right: 56px;
  margin-top: 45px;
  position: absolute;
}

.selection-box-icon img {
  height: 175px;
  width: 175px;
}

.selection-box-text {
  width: 290px;
  height: 63px;
  font-family: Ubuntu-Regular;
  font-size: 23px;
  line-height: 27.58px;
  color: #577df7;
  position: absolute;
  font-weight: bold;
  text-align: center;
  margin-top: 244px;
}

#main-wrapper-slides {
  height: 600px;
  width: 1560px;
  position: absolute;
  /* padding-left: calc(calc(calc(5px - calc(calc(100vh - 200px) / 346)) * 346) + 100px); */
  padding-top: 60px;
  margin-left: calc((100vw - 1560px)/2);

}

@media (max-width: 1560px) {
  #main-wrapper-slides {
    margin-left: calc((100vw - 1040px)/2);
    width: 1040px;
  }
}


@media (max-width: 1040px) {
  #main-wrapper-slides {
    margin-left: calc((100vw - 520px)/2);
    width: 520px;
  }
}

#main-wrapper-videos {
  height: 600px;
  width: 1560px;
  position: absolute;
  /* padding-left: calc(calc(calc(5px - calc(calc(100vh - 200px) / 346)) * 346) + 100px); */
  padding-top: 60px;
  margin-left: calc((100vw - 1040px)/2);

}

@media (max-width: 1040px) {
  #main-wrapper-videos {
    margin-left: calc((100vw - 520px)/2);
    width: 520px;
  }
}

#main-wrapper-settings {
  height: 600px;
  width: 520px;
  position: absolute;
  /* padding-left: calc(calc(calc(5px - calc(calc(100vh - 200px) / 346)) * 346) + 100px); */
  padding-top: 60px;
  margin-left: calc((100vw - 520px)/2);

}


.audience-box {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  margin: 34px;
  border: 2px solid #577df7;
  box-sizing: border-box;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}



.slides-box {
  width: 452px;
  height: 585px;
  float: left;
  position: relative;
  margin: 34px;
  border: 2px solid #577df7;
  box-sizing: border-box;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.slides-box-titlebox {
  height: 67px;
  width: 373px;
  margin-top: 25px;
}

.slides-box-icon {
  height: 67px;
  width: 67px;
  position: relative;
  float: right;
}

.slides-box-icon img {
  height: 67px;
  width: 67px;
}

.slides-box-text {
  width: 300px;
  height: 63px;
  padding-top: 15px;
  font-family: Ubuntu-Regular;
  font-size: 23px;
  line-height: 27.58px;
  color: #577df7;
  position: relative;
  font-weight: bold;
  text-align: center;
  float: left;
}

.slides-list-slide {
  height: 63px;
  width: 362px;
  margin-right: 45px;
  margin-left: 45px;
  border-radius: 4px;
}

.slides-list-slide:hover {
  background: #F2F1FF;
}

.slides-list-slide img {
  float: right;
  position: relative;
  margin-top: 12px;
  margin-right: 10px;
  visibility: hidden;
}

.slides-list-slide:hover img {
  visibility: visible;
}

.list-text {
  height: 63px;
  width: 362px;
  margin-right: 45px;
  margin-left: 45px;
  border-radius: 4px;
}

.list-text p {
  float: left;
  position: relative;
  font-family: Ubuntu-Regular;
  font-size: 23px;
  line-height: 27.58px;
  color: #577df7;
  text-align: center;
  margin-top: 16px;
}

.invisible {
  visibility: hidden;
}

.slides-list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 450px;
  width: 400px;
}

.slides-list ::-webkit-scrollbar {
  width: 3px;
}

.slides-list-slide p {
  float: left;
  position: relative;
  font-family: Ubuntu-Regular;
  font-size: 23px;
  line-height: 27.58px;
  color: #577df7;
  text-align: center;
  margin-top: 16px;
}

.select {
  height: 16px;
  width: 16px;
  margin-top: 23px;
  margin-bottom: 23px;
  margin-left: 15px;
  margin-right: 15px;
  float: left;
  position: relative;
  background-image: url("../img/Select1.png");
}

.selected {
  height: 16px;
  width: 16px;
  margin-top: 23px;
  margin-bottom: 23px;
  margin-left: 15px;
  margin-right: 15px;
  float: left;
  position: relative;
  background-image: url("../img/Select2.png");
}

.line {
  position: relative;
  width: 340px;
  height: 3px;
  margin-left: 55px;
  margin-right: 55px;
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid #577df7;
  border-radius: 4px;
  background-color: #577df7;
}

.slides-button {
  height: 63px;
  width: 352px;
  position: absolute;
  bottom: 25px;
  margin-left: 50px;
  margin-right: 50px;
}

.settings-text {
  height: 100px;
  width: 300px;
  margin-right: 60px;
  margin-left: 60px;
}

.settings-text p {
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 22px;
  color: #577df7;
  text-align: left;
  margin-top: 16px;
  font-weight: bold;
}

.settings-box {
  height: auto;
  width: 300px;
  margin-right: 60px;
  margin-left: 60px;
}

.settings-box p {
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 22px;
  color: #577df7;
  text-align: left;
  margin-top: 16px;
  font-weight: bold;
}

.input-settings {
  max-width: 150px;
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 22px;
  color: #577df7;
  text-align: left;
  margin-top: 8px;
  font-weight: bold;
  border: 2px solid #577df7;
  border-radius: 1px;
}

.input-description {
  width: 300px;
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 22px;
  color: #577df7;
  text-align: left;
  margin-top: 8px;
  font-weight: bold;
  border: 2px solid #577df7;
  border-radius: 1px;
}

.input-settings:active {
  border: 2px solid #577df7;
}

.file-button {
  height: 63px;
  width: 352px;
  position: absolute;
  bottom: 95px;
  margin-left: 50px;
  margin-right: 50px;
}

.coin-text-before {
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 22px;
  color: #577df7;
  text-align: center;
  margin-top: 90px;
  font-weight: bold;
  height: 24px;
  width: 300px;
  margin-right: 60px;
  margin-left: 60px;
}

.coin-text-after {
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 22px;
  color: #577df7;
  text-align: left;
  margin-top: 100px;
  font-weight: bold;
  height: 63px;
  width: 30px;
  float: left;
  position: relative;
}

.coin-num {
  font-family: Ubuntu-Regular;
  font-size: 120px;
  line-height: 120px;
  color: #577df7;
  text-align: left;
  margin-top: 16px;
  font-weight: bold;
  height: 120px;
  width: 70px;
  margin-left: 150px;
  float: left;
  position: relative;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/

.infograph {
  font-family: Ubuntu-Bold;
  color: #c2c6ce;
  line-height: 1.2;
  font-size: 18px;
  text-align: center;
  display: block;
  width: 100%;
  background: transparent;
  height: 62px;
  padding: 0 20px 0 20px;
}

.limiter {
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ;
}


.wrap-login100 {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  position: relative;
}


.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  display: inline-block;

  font-family: Ubuntu-Bold;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 62px;
  background-color: #577df7;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: #403866;
}

.inputfile+label {
  cursor: pointer;
  /* "hand" cursor */
}

.inputfile+label * {
  pointer-events: none;
}

/*==================================================================
[ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  font-family: Ubuntu-Bold;
  font-size: 30px;
  color: #403866;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;

  width: 100%;
  display: block;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: #e6e6e6;
  border: 1px solid transparent;
  border-radius: 3px;
}


/*---------------------------------------------*/
.input100 {
  font-family: Ubuntu-Bold;
  color: #403866;
  line-height: 1.2;
  font-size: 18px;

  display: block;
  width: 100%;
  background: transparent;
  height: 62px;
  padding: 0 20px 0 38px;
}

/*------------------------------------------------------------------
[ Focus Input ]*/

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #577df7;
  border-radius: 3px;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus+.focus-input100 {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.eff-focus-selection {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}


/*==================================================================
[ Restyle Checkbox ]*/

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-family: Ubuntu-Regular;
  font-size: 16px;
  color: #999999;
  line-height: 1.2;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  color: transparent;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background: #fff;
  border: 2px solid #577df7;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked+.label-checkbox100::before {
  color: #577df7;
}


/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.login100-form-btn {
  font-family: Ubuntu-Bold;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 62px;
  background-color: #577df7;
  border-radius: 3px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background-color: #403866;
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 3px;
  padding: 4px 25px 5px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  font-family: Ubuntu-Regular;
  color: #c80000;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}


.dash-three {
  padding-left: calc((100vw - 1008px)/2);
}

.dash-four {
  padding-left: calc((100vw - 1344px)/2);
}

/*//////////////////////////////////////////////////////////////////
[ Responsive ]*/