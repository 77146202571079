.modal-dialog {
   min-width: 0px !important;

   .btn-primary {
      background-color: #577df7;
      border-color: #577df7;
   }
}

.profileMain {
   background-color: #fff;
   border-radius: 8px;
   margin: auto;
   padding: 20px;
   transition: .3s;
   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

   .leftBox {
      .imgClass {
         width: 100%;
         height: 150px;
         padding-top: 25px !important;

         img {
            object-fit: cover !important;
            border: #577df7 2px solid;
            border-radius: 100% !important;
         }
      }

      .uploadImgButton {
         margin: auto;
         margin-top: 10px;
         width: 130px;
         height: 40px;
         font-size: 13px;
         line-height: 38px;
         border-radius: 8px;
         background-color: #577df7;
         border: 1px solid #fff;
         color: #fff;
         transition: .3s;
         position: relative;

         &.lang {
            background-color: transparent !important;

            &:hover {
               background-color: transparent !important;
               border-color: transparent !important;
            }
         }

         .fileInput {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 128px;
            height: 38px;
         }

         .fileInput:hover {
            cursor: pointer;
         }
      }

      .uploadImgButton:hover {
         background-color: #fff;
         color: #577df7;
         border-color: #577df7;
         cursor: pointer;
      }

      .textClass {
         margin-top: 20px;
      }
   }

   .rightBox {
      .rightTopBar {
         border-bottom: #cecece 1px solid;
         height: 50px;
         display: flex;

         .userName {
            font-size: 24px;
            text-align: left;
            color: #1e1e20;
         }

         .selectLang {
            margin-left: auto;

            span {
               padding-right: 10px;
               height: 40px;
               line-height: 40px;
            }

            .uploadImgButton {
               width: 200px;
               background-color: #fff !important;

               &.lang {
                  float: right;
                  margin-top: 0px !important;
               }
            }
         }
      }

      .form {
         margin-top: 10px;
         min-height: 300px;

         .profileRow {
            margin-top: 10px;
         }

         .label {
            font-size: 16px;
            text-align: right;
            color: #1e1e20;
         }

         .searchWrapper {
            .chip {
               background-color: #577df7;
            }

            input {
               border-bottom: none !important;
            }
         }

         .multiSelectContainer {
            li:hover {
               background-color: #577df7;
            }
         }

         .optionListContainer {
            .highlightOption {
               background-color: #577df7;

               &:hover {
                  background-color: #577df7 !important;
               }
            }
         }

         .description {
            word-break: break-all;
            text-align: left;
            color: #1e1e20;

            input {
               border-bottom: 1px solid #577df7;
            }
         }
      }

      .uploadImgButton {
         margin: auto;
         margin-top: 10px;
         width: 130px;
         height: 40px;
         font-size: 13px;
         line-height: 38px;
         border-radius: 8px;
         background-color: #577df7;
         border: 1px solid #fff;
         color: #fff;
         transition: .3s;
      }

      .uploadImgButton:hover {
         background-color: #fff;
         color: #577df7;
         border-color: #577df7;
         cursor: pointer;
      }
   }
}

.pairingCode {
   margin: auto;
   font-size: 40px;
   font-weight: 800;
   color: #577df7;
   width: 100%;
   display: block;
   text-align: center;
}

.time {
   text-align: center;
   color: #1e1e20;
   display: block;
   width: 100%;
}