.NoMargin {
   margin: 0px !important;
}

.labelBar {
   .label {
      display: flex;

      .labelMain {
         font-size: 25px !important;
         font-weight: 700 !important;
         color: #1e1e20 !important;
         text-align: left !important;
         line-height: 50px;
      }

      .titleBox {
         display: flex;

         .title {
            font-size: 20px;
            line-height: 50px;
            margin-right: 15px;
         }

         input {
            background-color: transparent;
            border-bottom: 1px solid #577df7;
            width: 100%;
         }
      }

      .newButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: 20px;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .newButtonMain:hover {
         border-color: #577df7 !important;
      }
   }
}

.pageRow {
   margin-top: 20px !important;

   .col-xl-12 {
      .settingBox {
         background-color: #fff;
         border-radius: 8px;
         margin: auto;
         padding: 20px;
         min-height: 300px;
         height: auto;
         margin-bottom: 20px;
         transition: .3s;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

         .questionBorder {
            border-bottom: #577df7 1px solid;
            margin: 0px;
            padding-bottom: 5px;
         }

         .questionLabel {
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
            text-align: left;
            border-bottom: #577df7 1px solid;
            padding-bottom: 10px;

            &.drama {
               border: none !important;
               padding-bottom: 0px;
               line-height: 35px;
            }

            .desc {
               font-weight: 400;
               font-size: 16px !important;
               color: #1e1e20 !important;
            }
         }

         .deleteButton {
            height: 100%;
            line-height: 35px;
            border-radius: 4px;
            background-color: tomato;
            color: #fff;
         }

         .questionBox {
            padding-top: 10px;

            .addBlankBox {
               text-align: left;

               .blankSpan {
                  margin-right: 2px;
                  color: #212529;
                  font-size: 1rem;
                  padding: 1px;
                  display: inline-block;
               }

               .selectedSpan {
                  font-size: 1rem;
                  display: inline-block;
                  border-radius: 2px;
                  background-color: #577df7;
                  margin-right: 2px;
                  padding: 1px;
                  color: #fff;
               }
            }

            .wordMatchBox {
               .inputBox {
                  input {
                     width: 100%;
                  }

                  .inputBoxLeft {
                     display: flex;
                     padding: 5px;
                     text-align: right;
                     border-bottom: 1px solid #577df7;

                     .number {
                        margin-right: 5px;
                     }
                  }

                  .inputBoxRight {
                     padding: 5px;
                     border-bottom: 1px solid #577df7;
                  }
               }
            }

            .dramaA {
               .inputBox {
                  width: 100%;
                  display: flex;
                  margin-top: 10px;
                  border-bottom: 1px solid #577df7;
               }

               .input {
                  width: 100%;

                  input {
                     width: 100%;
                  }
               }
            }

            .dramaDBox {
               .inputBox {
                  display: flex;

                  .pad-5 {
                     padding: 5px;
                     width: 100%;
                     border-bottom: solid 1px #577df7;

                     input {
                        width: 100% !important;
                     }
                  }
               }
            }

            .optionBox {
               margin-top: 10px;

               .optionInside {
                  padding-left: 10px;
                  display: flex;
                  border-bottom: 1px solid #577df7;
               }
            }

            .radioBox {
               .radioBoxInside {
                  height: 34px;
                  line-height: 34px;
                  text-align: left;
                  border-bottom: 1px solid #577df7;

                  input {
                     margin-left: 10px;
                  }
               }
            }

            .questionText {
               border-bottom: 1px solid #577df7;

               .number {
                  float: left;
                  font-weight: 800;
                  height: 100%;
                  line-height: 33px;
                  margin: auto;
                  margin-right: 10px;
               }

               input {
                  height: 100%;
                  width: 90%;
                  float: left;
               }
            }

            .selectBox {
               height: 100%;
               border: none !important;
               border-bottom: 1px solid #577df7 !important;
               border-radius: 0px;
               text-align: center !important;
               color: #577df7 !important;
            }
         }

         .addQueButtonDrama {
            height: 35px;
            background-color: #577df7;
            color: #fff;
            border-radius: 4px;
            line-height: 35px;

            input {
               height: 35px;
               line-height: 35px;
            }
         }

         .addQueButtonDramaInput {
            height: 35px;
            color: #fff;
            border-radius: 4px;
            line-height: 35px;

            input {
               height: 35px;
               line-height: 35px;
               width: 100%;
            }

            input[type=number]::-webkit-outer-spin-button {
               opacity: 1;
            }
         }

         .addQueButton {
            height: 50px;
            background-color: #577df7;
            color: #fff;
            border-radius: 8px;
            line-height: 50px;
            margin-top: 10px;
         }

         .addBlank {
            height: 50px;
            text-align: center;
            background-color: #577df7;
            color: #fff;
            font-weight: 300;
            border-radius: 8px;
            line-height: 50px;
         }
      }

      .settingBox:hover {
         cursor: default !important;
         box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
   }
}