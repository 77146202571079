@font-face {
  font-family: Ubuntu-Bold;
  src: url(./fonts/Ubuntu/Ubuntu-Bold.ttf);
}

@font-face {
  font-family: Ubuntu-Light;
  src: url(./fonts/Ubuntu/Ubuntu-Light.ttf);
}

@font-face {
  font-family: Ubuntu-Regular;
  src: url(./fonts/Ubuntu/Ubuntu-Regular.ttf);
}


.float {
  position: relative;
}

.left {
  float: left;
}

.right {
  float: right;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #F7F6FF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #F7F6FF;
}

.Page {
  margin-top: 110px;
  height: calc(100% - 110px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.topBar {
  width: 100%;
  margin: 0;
  top: 0;
  height: 110px;
  position: fixed;
  z-index: 1;

  background: #FFFFFF;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
}

.top-1 {
  height: 110px;
  width: 134px;
  left: 0px;
  float: left;
}

.top-1-logo {
  max-height: 100px;
  margin: 5px;
}

.top-2 {
  height: 110px;
  width: calc(100% - 490px);
  position: relative;
  float: left;
  justify-content: center;
  /* margin:auto; */

}

.top-3 {
  height: 110px;
  width: 350px;
  padding: auto;
  float: right;
  position: relative;
  right: 0;
}

.top-user {
  margin-top: 25px;
}

.topBar-right {
  height: 60px;
}

.topBar-right-profilepic {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  border-color: #577df7;
  border-width: 2px;
  border: solid;
}

.topBar-right img {
  height: 60px;
  width: 60px;
}

.topbar-name {
  text-align: left;
  padding-left: 4px;
  padding-top: 6px;
  width: 220px;
  height: 60px;

}

.topbar-name h5 {
  padding: 0;
  margin: 0;
}

.topbar-name h3 {
  padding: 0;

  margin: 0;
}


.topbar-image {
  height: 87px;
  width: 87px;
}

.topBar-button-wrapper {
  display: flex;
  justify-content: center;
}

.topBar-button {
  position: relative;
  float: left;
  height: 100px;
  width: 100px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 10px;
  justify-content: center;
}

@keyframes visibilityOff {
  0% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}

.line-2 {
  height: 0px;
  width: 0%;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #577df7;
  border: 2px solid #577df7;
  transition: width 0.5s;
}

.hidden {
  width: 0px;
  height: 0px;
  transition: width 0.5s, visibility 0.4s;
  visibility: hidden;
}

.visible {
  width: 100%;
}

.line-2-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}




.topBar-button:hover>.line-2-wrapper>.hidden {
  width: 100%;
  visibility: visible;
  transition: width 0.5s;
}



.appBody {
  width: 100%;
  height: 100%;
  background-color: #F7F6FF;
}

.body-header {
  margin-top: 30px;
}

.body-body {
  margin-top: 30px;
}

.default-box {
  position: relative;
  justify-content: left;

  background: #FFFFFF;
  border: 2px solid #577df7;
  box-sizing: border-box;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px;
}

.class-box-col {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.class-box {
  width: 355px;
  height: 414px;
  padding-top: 30px;
  position: relative;

}

.class-box-wrapper {
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 60px);
}

.round-btn {
  background: #FFFFFF;
  border: 2px solid #577df7;
  box-sizing: border-box;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 41px;
  width: 167px;
  height: 71px;
  color: #577df7;
  font-size: 24px;
  font-family: Ubuntu-Bold;
}

.round-btn:hover {
  background: #e9e9ff;
}

.line-1 {
  width: 100%;
  height: 0px;
  border-radius: 4px;
  margin-bottom: 20px;

  background-color: #577df7;
  border: 1px solid #577df7;
}

.line-3 {
  width: 100%;
  height: 0px;
  border-radius: 4px;

  background-color: #577df7;
  border: 1px solid #577df7;
}

h1 {
  font-family: Ubuntu-Bold;
}

h3 {
  font-family: Ubuntu-Bold;
  font-size: 24px;
}

h5 {
  font-family: Ubuntu-Regular;
  font-size: 20px;
  justify-content: left;
}

.font-purple {
  color: #577df7;
}

.box-button {
  text-align: left;
  padding: 10px 15px;
  width: 100%;
  height: 46px;
}

.box-button:hover {
  background: rgba(130, 127, 254, 0.2);
  border-radius: 15px;
}

.box-text-left {
  width: auto;
  float: left;
}

.box-text-right {
  width: auto;
  float: right;
}

.input-text-left {
  text-align: right;
}

.row-margin-5 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.form-title {
  border: none;
  margin: 0 30px;
  color: #577df7;
  width: calc(100% - 60px);
  line-height: 32px;
  font-size: 24px;
}

.form-title:focus {
  outline: none;
  border: none;
  color: #577df7;
}

.user-img-mini {
  height: 40px;
  width: 40px;
  margin-right: 20px;
}

.user-img-mini img {
  height: 40px;
  width: 40px;

}

.user-manage-box-mini {
  height: 60px;
  width: 400px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 10px;

}

.user-manage-text {
  padding-top: 6px;
}

.assignment-check-box-mini {
  height: 100px;
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 10px;
  padding: 10px;
  padding-left: 30px;
}

.assignment-check-box-left {
  width: 350px;
}

.assignment-check-title {
  font-family: Ubuntu-Regular;
}

.assignment-check-author {
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 18px;
}

.assignment-check-box-right {
  justify-content: center;
  display: flex;
  text-align: center;
  margin: auto;
}

.file-titles {
  text-align: left;
  padding: 10px 15px;
  width: 100%;
  height: 46px;
  font-family: Ubuntu-Bold;
}

.file-item {
  text-align: left;
  padding: 10px 15px;
  width: 100%;
  height: 46px;
}

.files-default-box {
  padding-bottom: 30px;
}

.library-title {
  text-align: left;
  padding: 5px 25px;
}

.question-number {
  width: 10px;
  float: left;
}

.text-input-full {
  width: calc(100% - 20px);
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #577df7;

}

.text-input-full:hover {
  width: calc(100% - 20px);
}

.test-question {
  margin: 40px 10px;

}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.add-button {
  background: #FFFFFF;
  background-color: rgba(130, 127, 254, 0.2);
  box-sizing: border-box;

  width: 400px;
  height: 71px;
  color: #577df7;
  font-size: 24px;
  line-height: 70px;
  font-family: Ubuntu-Regular;
  margin: auto;
}

.add-button:hover {
  background-color: rgba(77, 74, 255, 0.452);
  color: white;
}

.profile-wrapper {
  margin: 50px;
}

.profile-button {
  width: 300px;
  margin: auto;
  margin-top: 40px;
}

.new-file {
  position: absolute;
  z-index: 2;
  justify-content: left;
  height: 400px;
  width: 640px;

  background: #FFFFFF;
  border: 2px solid #577df7;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.35);
  border-radius: 40px;
  padding: 20px 30px;
}

.new-title {
  width: 100%;
  height: 30px;
  float: left;
}

.new-left-wrapper {
  margin-left: 30px;
  margin-top: 20px;
  padding-right: 60px;
  width: 250px;
  height: 300px;
  justify-content: left;
  text-align: left;
  float: left
}

.new-right-wrapper {
  margin-left: 30px;
  margin-top: 20px;
  padding-right: 60px;
  width: 250px;
  height: 300px;
  justify-content: left;
  text-align: left;
  float: left
}

.create-text {
  padding: 0;
  margin: 0;
  margin-top: 2px;
}

.new-button {
  padding: 4px;
  padding-top: 1px;

}

.new-button:hover {
  background: rgba(130, 127, 254, 0.2);
  border-radius: 2px;
}

.upload-box {
  justify-content: center;
  height: 260px;
  width: 200px;
  text-align: center;

  margin-left: 20px;
  border: 2px solid #577df7;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 20px 30px;

  padding-top: 70px;
}

.upload-box:hover {
  background: rgba(130, 127, 254, 0.2);
}

.close {
  height: 30px;
  width: 30px;
  border-radius: 5px;
}

.close:hover {
  background: rgba(130, 127, 254, 0.2);
}

.no-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.profilepic {
  max-height: 400px;
}