.animCenter {
   position: absolute;
   top: 50%;
   /* position the top  edge of the element at the middle of the parent */
   left: 50%;
   /* position the left edge of the element at the middle of the parent */
   transform: translate(-50%, -50%);

   .spinner-border {
      border: 0.25em solid #577df7;
      border-right-color: transparent;
      width: 5rem;
      height: 5rem;
   }
}

.mb-3 {
   margin-bottom: 0px !important;
}

.noUserFound {
   width: 100%;
   padding: 30px 10px;
}

.labelBar {
   .selectLibrary {
      background-color: #fff !important;
      color: #577DF7 !important;
      border: 2px solid #fff !important;
      padding: 10px 20px;
      width: 250px;
      outline: none !important;
      font-size: 16px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

      &:focus {
         background-color: #fff;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
         outline: none !important;
         color: #577df7 !important;
      }

      &:active {
         background-color: #fff !important;
      }
   }

   .drop {
      width: 250px !important;

      .dropdown-item:active {
         background-color: #577df7 !important;
      }
   }

   .selectLibrary {
      &.full {
         width: 100%;
      }
   }

   .drop {
      &.full {
         width: 100% !important;
      }
   }

   .label {
      display: flex;

      &.full {
         flex-wrap: wrap !important;

         .labelMain {
            width: 100%;
         }

         a {
            width: 100%;
         }

         .infoButtonMain {
            width: 100%;
            margin: 0px !important;
            margin-top: 10px !important;
         }

         .newButtonMain {
            margin: 0px !important;
            margin-top: 10px !important;
            width: 100%;

            .buttonText {
               margin-left: 10px;
               font-size: 16px;
               font-weight: 700;
               color: #577df7;
            }
         }
      }

      .labelMain {
         font-size: 25px;
         font-weight: 700;
         color: #1e1e20 !important;
         text-align: left !important;
         line-height: 50px;
      }

      .newButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: auto;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .newButtonMain:hover {
         border-color: #577df7 !important;
      }

      .infoButtonMain {
         padding: 10px 20px;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
         margin-left: 10px !important;
         border: 2px solid #fff;
         transition: .5s;

         .buttonText {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #577df7;
         }
      }

      .infoButtonMain:hover {
         border-color: #577df7 !important;
         cursor: pointer;
      }
   }
}

.pageRow {
   margin-top: 20px !important;

   &.admin {
      margin-top: 0px !important;
   }

   .col-xl-12 {
      .settingBox {
         &.files {
            max-height: calc(100vh - 260px);
            overflow: hidden;
         }

         &.admin {
            border-radius: 0px 0px 8px 8px;
            box-shadow: none;
            border: 1px solid #dee2e6;
            border-top: none;

            .newButtonMain {
               padding: 0px 20px;
               background-color: #fff;
               border-radius: 4px;
               border: 1px solid #dee2e6 !important;
               margin-left: 20px;
               height: 60px;
               border: 2px solid #fff;
               transition: .5s;
               line-height: 55px;

               .buttonText {
                  margin-left: 10px;
                  font-size: 16px;
                  font-weight: 700;
                  color: #577df7;
               }
            }

            .newButtonMain:hover {
               cursor: pointer;
               border-color: #577df7 !important;
            }

            .infoButtonMain {
               padding: 0px 20px;
               height: 60px;
               background-color: #fff;
               border-radius: 4px;
               border: 1px solid #dee2e6 !important;
               margin-left: 10px !important;
               border: 2px solid #fff;
               transition: .5s;
               line-height: 55px;

               .buttonText {
                  margin-left: 10px;
                  font-size: 16px;
                  font-weight: 700;
                  color: #577df7;
               }
            }

            .infoButtonMain:hover {
               border-color: #577df7 !important;
               cursor: pointer;
            }
         }

         background-color: #fff;
         border-radius: 8px;
         margin:auto;
         padding:20px;
         min-height: 200px;
         height: auto;
         transition: .5s;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
         rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

         .topBarDiv {
            margin: 0px !important;
            border-bottom: 2px solid #dddddd;
            margin-bottom: 10px !important;

            .filesLabel {
               display: flex;
               font-size: 20px;
               font-weight: 700;
               color: #577df7;
               text-align: left;
               line-height: 58px;

               .topLabelButton {
                  .buttonText {
                     margin-left: 10px;
                     font-size: 16px;
                     font-weight: 700;
                     color: #577df7;
                  }

                  .customDrop {
                     padding: 0px !important;
                     height: 36px;
                     background-color: transparent;
                     border: none;

                     &:focus {
                        box-shadow: none !important;
                     }
                  }

                  .dropdown-toggle::after {
                     display: none;
                  }
               }

               .shareMainButton {
                  padding: 0px 10px;
                  cursor: pointer;
                  line-height: 44px !important;
                  margin-top: 4px;
                  background-color: #fff;
                  height: 50px;
                  border-radius: 4px;
                  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
                  margin-left: 10px !important;
                  border: 2px solid #fff;
                  transition: .5s;

                  .buttonText {
                     margin-left: 10px;
                     font-size: 16px;
                     font-weight: 700;
                     color: #577df7;
                  }
               }
            }

            .searchBarDiv {
               padding: 10px;

               .searchBar {
                  transition: .5s;
                  border: 2px solid #dddddd !important;
                  border-radius: 4px;
                  margin-right: 10px;
               }

               .searchBar:focus {
                  border: 2px solid #577df7 !important;
                  box-shadow: none !important;
               }

               .searchButton {
                  transition: .5s;
                  background-color: #577df7 !important;
                  border: none !important;
               }

               .searchButton:hover {
                  background-color: #577DF7 !important;
                  border: none !important;
               }
            }
         }

         .scrollable {
            overflow-y: auto;
            height: calc(100vh - 440px);
            min-height: 180px;

            &::-webkit-scrollbar-track {
               right: 0px;
               -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
               background-color: #F5F5F5;
               border-radius: 12px;
            }

            &::-webkit-scrollbar {
               width: 12px;
               background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
               border-radius: 12px;
               -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
               background-color: #577df7;
            }
         }

         .customTableRowLabel {
            border: none !important;
            border-width: 0px !important;
            border-bottom: 1px solid #c9c8ff !important;
            background-color: #fff !important;
            transition: .5s;

            th {
               width: 20% !important;
               color: #577df7 !important;
               text-align: left;
               border-width: 0px !important;
               border: none !important;
               cursor: pointer !important;
            }

            .actions {
               text-align: center !important;
               padding-left: 10px;
            }
         }

         .customTableRow {
            border-top: none !important;
            border-bottom: 1px solid #c9c8ff !important;
            background-color: #fff !important;
            transition: .5s;

            td {
               word-break: break-word;
               max-width: 20% !important;
               border: none !important;
               vertical-align: middle;
               border-width: 0px !important;
               text-align: left;
               --bs-table-accent-bg: none !important;
               transition: .5s;
               color: #1e1e20 !important;
               cursor: default;
            }

            .actions {
               text-align: center !important;
               padding-left: 10px;
            }
         }

         .customTableRow:hover {
            background-color: #eef2fe !important;
         }

         .dropdownActions {
            background-color: #577df7 !important;
            border: none !important;
            box-shadow: none !important;
         }
      }

      .settingBox:hover {
         cursor: default !important;
         box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
   }
}

.createNewModal {
   a {
      text-decoration: none !important;
   }

   .leftCols {
      padding: 3px !important;
      border-bottom: rgba(130, 127, 254, 0.5) 1px solid !important;
      transition: .3s;

      .leftColsLinkText {
         text-align: left;
         color: #1e1e20;
         font-weight: 500;
         font-size: 16px !important;
         text-decoration: none !important;
      }
   }

   .leftCols:hover {
      cursor: pointer;
      padding-left: 10px !important;
      border-bottom: #577df7 1px solid !important;
   }

   .rightDiv {
      .uploadFile {
         background-color: #577df7;
         border-radius: 4px;
         width: 100%;
         height: 30px;
         color: #fff;
         text-align: center;
         margin-top: 10px;
         cursor: pointer;
         line-height: 30px;
         transition: .5s;

         &:hover {
            background-color: #577DF7 !important;
         }
      }

      .uploadDiv {
         border: 1px solid #577df7;
         width: 100%;
         margin: auto;
         margin-top: 9px;
         text-align: center;
         position: relative;
         height: 200px;
         padding: 10px;
         border-radius: 8px;

         .uploadLogo {
            width: 100%;
            display: block;
            height: 140px;
            line-height: 180px;
         }

         .uploadText {
            word-break: break-all;
            color: #577df7;
            font-size: 14px;
            display: block;
         }

         .fileInput {
            opacity: 0.0;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
         }

         .fileInput:hover {
            cursor: pointer;
         }
      }

      .uploadDiv:hover {
         cursor: pointer;
      }
   }
}

.copyButton {
   background-color: #577df7;
   border: none !important;

   &:hover {
      background-color: #577DF7;
   }
}