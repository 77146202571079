.labelBar {
   .lectureLabel {
      font-size: 23px;
      font-weight: 700;
      color: #1e1e20;
      text-align: left !important;
   }

   .lectureAverage {
      font-size: 23px;
      font-weight: 700;
      color: #577df7;
      text-align: right !important;
   }
}

.assiLabel {
   margin: top 10px;
   font-size: 18px;
   font-weight: 800;
   color: #577df7;
   border-bottom: #577df7 2px solid;
}

.assiInside {
   padding: 10px;

   &.grade {
      input {
         width: 50px;
      }
   }
}

.pageRow {
   margin-top: 30px !important;

   .col-xl-3 {
      .gradesBox {
         background-color: #fff;
         border-radius: 8px;
         margin: auto;
         margin-top: 20px;
         padding: 20px;
         height: auto;
         transition: .3s;
         box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

         .topLabel {
            height: 20px;
            text-align: left;
            font-weight: 700;
            padding-bottom: 35px;
            border-bottom: 1px solid #B6B6B6;
         }

         .last_nextClasss {
            text-align: left;
            font-size: 14px;
         }

         .tableRow {
            transition: .5s;
            margin-top: 10px;
            display: flex;
            height: auto;

            .lectureName {
               word-break: break-word;
               width: 75%;
               text-align: left;
               line-height: 30px;
               font-size: 14px;
            }

            .grade {
               margin: auto;
               font-weight: 700;
               color: #577df7;
               width: 25%;
               text-align: right;
            }
         }

         .tableRow:hover {
            background-color: #f2f2f2;
            border-radius: 4px;
         }

         .textClass {
            width: 100%;
            font-size: 18px;
            font-weight: 700;
            color: #577df7;
            line-height: 50px;
            height: 50px;
         }

         .imgClass {
            padding-top: 50px;
         }
      }

      .gradesBox:hover {
         box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
   }
}

.pageRow {
   .gradesBox:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      cursor: pointer;
   }
}